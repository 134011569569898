<template>
  <article
    class="in-product product position-relative bg-white box-shaddow20 border-r-20"
    :style="lowOpacity ? 'opacity: 0.7' : ''"
  >
  <template v-if="product && product.major_final_price">
     
      <DiscountTag
        class="product-discount-tag"
        :max_discount_variety="product.max_discount_variety"
        :finalPrice="product.major_final_price"
      />
    </template>
    <div
      v-if="product && comparable"
      @click="$store.commit('front/toggleCompare', { product, $root })"
      class="compare-btn"
      :selected="isComparing"
    >
      مقایسه
      <Tick class="mr-1" :value="isComparing" />
    </div>
    <a @click.prevent="cachProduct" :href="to.href" class="text-center">
      <!--      <div class="recommended-products-img text-center" :style="!product ? 'background-image: radial-gradient(#f4f4f4,#f4f4f4,#f4f4f4)' : ''">-->
      <div class="recommended-products-img text-center">
        <img
          loading="lazy"
          v-if="product && mainImage"
          class="border-r-20-image"
          :src="getImage(mainImage, 'lg')"
          :alt="product.title"
        />
        <img
          loading="lazy"
          v-else
          class="skeleton-image"
          :alt="product ? product.title : ''"
        />
      </div>
    </a>
    <div v-if="product" class="text-center position-relative p-3">
      <div
        class="product-rate d-flex align-items-center justify-content-center"
      >
        <!--          <b-form-rating :value="product.rate" disabled size="sm" />-->
        <!--          <span class="fontsize11 text-color-999">(<span>{{product.rate}}</span>)</span>-->
      </div>
      <a @click.prevent="cachProduct" :href="to.href" class="text-center">
        <h1
          v-if="$route.path != '/'"
          class="title-products title-products-overflow text-color-444 weight-bold fontsize-small mt-2 pb-3"
        >
          {{ product.title }}
        </h1>

        <h2
          v-else
          class="title-products-overflow text-color-444 weight-bold fontsize-small mt-1 mb-3"
        >
          {{ product.title }}
        </h2>
      </a>
      <div class="d-flex justify-content-between align-items-center">
        <ProductFavorite
          :productId="product ? product.id : null"
          class="mr-2"
        />
        <a @click.prevent="cachProduct" :href="to.href" class="text-center">
          <div
            v-if="product.status == 'available'"
            class="d-flex flex-column align-items-center justify-content-center"
          >
            <div>
              <span class="text-color-theme fontsize-medium">
                {{ $root.priceFilter(mainPrice) }}
              </span>
              <span class="text-color-666 fontsize-small pr-1">تومان</span>
            </div>
            <span v-if="discountAmount != 0">
              <span class="line-through text-color-999 fontsize-mini">{{
                $root.priceFilter(mainPrice + discountAmount) + "تومان"
              }}</span>
            </span>
          </div>
          <div v-else class="hide-by-hover">
            <span
              v-if="product.status == 'out_of_stock'"
              class="text-color-themeRed fontsize14"
            >
              تمام شد
            </span>
            <span
              v-if="product.status == 'soon'"
              class="text-color-themeRed fontsize14"
            >
              به زودی
            </span>
          </div>
        </a>
        <a @click.prevent="cachProduct" :href="to.href" class="text-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.4899 22.75H7.49993C5.77993 22.75 4.48994 22.29 3.68994 21.38C2.88994 20.47 2.57993 19.15 2.78993 17.44L3.68994 9.94C3.94994 7.73 4.50994 5.75 8.40994 5.75H15.6099C19.4999 5.75 20.0599 7.73 20.3299 9.94L21.2299 17.44C21.4299 19.15 21.1299 20.48 20.3299 21.38C19.4999 22.29 18.2199 22.75 16.4899 22.75ZM8.39993 7.25C5.51993 7.25 5.37993 8.38999 5.16993 10.11L4.26994 17.61C4.11994 18.88 4.29993 19.81 4.80993 20.38C5.31993 20.95 6.21993 21.24 7.49993 21.24H16.4899C17.7699 21.24 18.6699 20.95 19.1799 20.38C19.6899 19.81 19.8699 18.88 19.7199 17.61L18.8199 10.11C18.6099 8.37999 18.4799 7.25 15.5899 7.25H8.39993Z"
              fill="#A5A5A5"
            />
            <path
              d="M16 8.75C15.59 8.75 15.25 8.41 15.25 8V4.5C15.25 3.42 14.58 2.75 13.5 2.75H10.5C9.42 2.75 8.75 3.42 8.75 4.5V8C8.75 8.41 8.41 8.75 8 8.75C7.59 8.75 7.25 8.41 7.25 8V4.5C7.25 2.59 8.59 1.25 10.5 1.25H13.5C15.41 1.25 16.75 2.59 16.75 4.5V8C16.75 8.41 16.41 8.75 16 8.75Z"
              fill="#A5A5A5"
            />
            <path
              d="M20.41 17.78H8C7.59 17.78 7.25 17.44 7.25 17.03C7.25 16.62 7.59 16.28 8 16.28H20.41C20.82 16.28 21.16 16.62 21.16 17.03C21.16 17.44 20.82 17.78 20.41 17.78Z"
              fill="#A5A5A5"
            />
          </svg>
        </a>
      </div>
    </div>

    <div v-else class="mt-3 px-4 px-sm-5">
      <b-skeleton />
      <b-skeleton class="mt-2" />
    </div>
    <!-- <section>
        <ProductCongratulations
          v-if="showCongratulations"
          v-model="showCongratulations"
        />
      </section> -->
  </article>
</template>

<script>
import { BFormRating, BSkeleton } from "bootstrap-vue";
// import ProductCongratulations from "@/parts/Front/components/productDetail/ProductCongratulations";
import ProductFavorite from "@/parts/Front/components/productDetail/ProductFavorite";

import { getImages, sortVarieties, getImage } from "@/libraries/functions";
import Tick from "@/parts/Front/components/shared/Tick";
import DiscountTag from "@/parts/Front/components/product/DiscountTag.vue";
export default {
  name: "Product",
  components: {
    Tick,
    DiscountTag,
    BFormRating,
    BSkeleton,
    // ProductCongratulations,
    ProductFavorite,
    DiscountTag,
  },
  props: {
    product: Object || Array,
    lowOpacity: Boolean,
    comparable: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      // showCongratulations: false,
      // disabled: false,
      // mainProduct: this.product,
    };
  },
  methods: {
    getImage,

    cachProduct() {
      let product = this.product;
      product.isPreview = true;
      this.$store.commit("front/setProductLinkedInfo", product);
      this.$router.push(this.to.href);
    },

    //   alertLogin() {
    //     this.$root.notify(
    //       "برای افزودن محصول به لیست مورد علاقه ابتدا وارد سایت شوید."
    //      , 'warning');
    //   },
    //   async addFavorite() {
    //     if (this.disabled) {
    //       return;
    //     }
    //     this.disabled = true;
    //     try {
    //       const response = await this.$axios.post(
    //         `customer/products/${this.productId}/favorite`
    //       );
    //       this.$root.notify(response.data.message, "success");
    //       if (response.data.data) {
    //         this.$store.commit("front/addUserFavorite", response.data.data.product);
    //       }
    //       this.hasFavorite = true;
    //     } catch (error) {
    //       this.$root.notify(error);
    //     }
    //     this.disabled = false;
    //   },
    // },
    // async addToCart() {
    //   this.disabled = true;
    //   try {
    //     const response = await this.mainProduct.addToCart();
    //     if (this.isMobile) {
    //       this.showCongratulations = true;
    //     } else {
    //       this.$root.notify(response.data.message, "success");
    //       cartListsOpen();
    //       this.$router.push(this.$route.fullPath + "#cart-modal");
    //     }
    //   } catch (error) {
    //     if (error.message === "SELECT-VARIETY") {
    //       this.$root.debouncedNotify(
    //         "لطفا گزینه های محصول را انتخاب کنید",
    //         "warning"
    //       );
    //       let scrollDiv = document.querySelector(".attributes-container")
    //         .offsetTop;
    //       window.scrollTo({ top: scrollDiv - 100, behavior: "smooth" });
    //     } else if (error.message === "SELECT-QUANTITY") {
    //       this.$root.debouncedNotify("لطفا تعداد را انتخاب کنید", "warning");
    //     } else if (error.message === "NO_STOCK") {
    //       // This error may happen if cart is in "cookie" mode
    //       this.$root.errorNotify("موجودی محصول کافی نیست");
    //     } else {
    //       this.$root.notify(error);
    //     }
    //   } finally {
    //     this.disabled = false;
    //   }
    // },
  },
  computed: {
    to() {
      return this.$router.resolve({
        name: "product.detail",
        params: { id: this.product.id, slug: this.product.slug },
      });
    },
    mainPrice() {
      // jor keshi front
      let sort =
        this.$route.query.sort &&
        (this.$route.query.sort == "high_to_low" ||
          this.$route.query.sort == "low_to_high");
      return sort
        ? this.product
          ? this.$route.query.sort == "low_to_high"
            ? this.product.minor_variety_price
            : this.product.major_variety_price
          : null
        : this.product
        ? this.product.price
        : null;
    },
    discountAmount() {
      return this.product ? this.product.major_discount_amount : null;
    },
    mainImage() {
      return this.product ? this.product.major_image : null;
    },
    isComparing() {
      return Boolean(
        this.$store.getters["front/getCompares"].find(
          (i) => i.id == this.product.id
        )
      );
    },
    //   isAvailable() {
    //     // آیا قابل خرید هست اصلا
    //     // حتی اگر یک تنوعش قابل خرید باشه هست
    //     return (
    //       this.product && this.product.status === "available" && this.hasQuantity
    //     );
    // },
    // hasQuantity() {
    //     if (this.product && !this.product.isPreview)
    //       return this.product && this.product.getMaxQuantity() > 0;
    //     return 0;
    //   },

    // isMobile() {
    //   return this.mediaQueries.mobileSize;
    // },
  },
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .recommended-content-item article:hover .hide-by-hover {
    opacity: 1 !important;
  }
}
.product-discount-tag {
 /* transform: rotate(-90deg);
  transform-origin: bottom right;*/
}
.title-products-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.title-products {
  border-bottom: 1px dashed rgb(206, 206, 206);
}

.skeleton-image {
  width: 308px;
  display: inline-block;
  background-image: radial-gradient(
    rgb(244, 244, 244),
    rgb(244, 244, 244),
    rgb(244, 244, 244)
  );
}
.border-r-20-image {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.compare-btn {
  z-index: 2;
  position: absolute;
  left: 15px;
  top: 10px;
  background: white;
  border-radius: 4px;
  padding: 1px 9px;
  border: 1px solid #7570b3;
  font-size: 13px;
  transition: 200ms all;
  cursor: pointer;
}

.compare-btn:not([selected]) {
  opacity: 0;
}

.compare-btn[selected] {
  opacity: 1 !important;
}
.product{
 overflow: hidden;
}
.in-product:hover .compare-btn {
  transition: 200ms all;
  opacity: 0.6;
  pointer-events: unset;
}
.compare-btn input {
  transform: translateY(3px);
}
.compare-btn input:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .compare-btn:not([selected]) {
    opacity: 0.7;
  }
}

.compare-btn-mobile {
  width: 35px;
  height: 35px;
  transition: all 0.3s;
  background: #555555;
  border-radius: 50%;
  padding: 7px;
  margin-right: 8px;
}
.compare-btn-mobile[selected] {
  background: var(--color-themeBlue);
}
</style>
<style>
.product-rate .b-rating.disabled,
.b-rating:disabled {
  background-color: unset !important;
}
.product-rate .form-control {
  padding: 0;
  border: unset !important;
  width: 130px;
  direction: ltr;
}
.product-rate .b-rating .b-rating-star {
  padding: 0;
}

.show-by-hover.recommended-title-hover {
  position: absolute;
  bottom: 0;
}

.py-25 {
  padding: 14px 0;
}
</style>
