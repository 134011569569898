<template>
  <div class="in-login-register">
    <transition name="fade">
      <form
          @submit.prevent="login"
          v-if="loginSection"
          id="loginSection"
          :class="{ 'col-md-9': showFullForm, marginAuto: showFullForm }"
          class="modal-body px-4 pb-3"
      >
        <div class="modal-custom-line"></div>
        <div
            class="d-flex align-items-baseline gap-5 justify-content-between mt-3"
        >
          <div class="d-flex align-items-baseline gap-5">
            <svg
                width="10"
                height="10"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.20431 0.00436162C4.32696 -0.0132274 4.45022 0.0230506 4.54828 0.101096C4.64904 0.180241 4.71207 0.294564 4.72577 0.422626C4.75475 0.681494 4.56797 0.915635 4.30947 0.944768C2.52654 1.14373 1.14076 2.53262 0.940281 4.3211C0.913441 4.56071 0.712416 4.74155 0.472506 4.74155C0.454411 4.74155 0.436866 4.74045 0.418825 4.73825C0.29337 4.7245 0.181666 4.66241 0.102741 4.56346C0.0244207 4.46457 -0.0111647 4.34143 0.00308037 4.21557C0.252836 1.9841 1.97989 0.252241 4.20431 0.00436162ZM4.17004 1.91947C4.4319 1.8711 4.67236 2.03818 4.72219 2.29376C4.77202 2.54933 4.60443 2.79886 4.34973 2.84888C3.58286 2.99837 2.99074 3.59197 2.8407 4.36144C2.79796 4.58293 2.60293 4.74452 2.37837 4.74452C2.34823 4.74452 2.31814 4.74177 2.28745 4.73627C2.03275 4.68517 1.86516 4.43619 1.91499 4.18007C2.13901 3.0308 3.02362 2.14316 4.17004 1.91947ZM3.29995 7.19977C3.05168 7.05661 2.77118 6.89491 2.41742 6.97015C2.09699 7.03774 1.0048 7.92429 0.705706 8.23157C0.50963 8.43271 0.40062 8.64044 0.383625 8.84823C0.354091 9.66333 1.46163 10.5938 1.6632 10.7098C1.93105 10.9027 2.24268 11 2.5927 11C2.95037 11 3.34917 10.8983 3.78406 10.6955C6.14372 9.71058 9.74846 6.17705 10.7015 3.83567C11.097 2.96397 11.0997 2.24506 10.7081 1.70479C10.552 1.45141 9.66192 0.392288 8.86494 0.42581C8.65297 0.443949 8.44702 0.552778 8.2449 0.75064C7.93926 1.05018 7.07491 2.14558 7.00808 2.46711C6.93414 2.82437 7.09627 3.10852 7.24033 3.35805C7.27105 3.41169 7.31245 3.47534 7.35781 3.54508C7.52994 3.80976 7.75905 4.16204 7.67798 4.38694C7.09682 5.81378 5.75432 7.05864 4.32685 7.64346C4.10614 7.7237 3.75403 7.49293 3.49019 7.32006C3.4215 7.27501 3.35874 7.23387 3.30589 7.20318L3.29995 7.19977Z"
                  fill="#4F4F4F"
              />
            </svg>
            <p class="fontsize12 text-color-grey">کد ارسال شده به شماره</p>
          </div>
          <p class="fontsize12 text-color-grey">|</p>
          <span class="fontsize12 text-color-grey">{{ telephone }}</span>
          <button
              @click.prevent="editLoginTelephone()"
              type="button"
              class="edit-number"
          >
            <span class="fontsize12">ویرایش شماره</span>
          </button>
        </div>

        <div :class="{ paswordInputFullForm: showFullForm }">
          <div class="d-flex align-items-center border border-r-25 p-1">
            <!-- با کلیک روی چشم پسورد نمایش داده میشود -->
            <i
                v-if="showPass"
                class="fa fa-eye"
                @click="showPass = !showPass"
                style="cursor: pointer"
            ></i>
            <i
                v-else
                class="fa fa-eye-slash"
                @click="showPass = !showPass"
                style="cursor: pointer"
            ></i>
            <input
                v-model="loginPassword"
                class="loginInputs"
                :type="isSafari() ? 'text' : 'password'"
                id="passWord"
                ref="passWord"
                :style="mediaQueries.mobileSize ? 'direction: ltr' : ''"
                placeholder="لطفا رمز عبور خود را وارد کنید"
            />
          </div>
          <div>
            <input
                :class="{ disableLink: disabled1 }"
                type="submit"
                class="p-2 mt-2 fontsize-medium weight-bold bg-color-theme text-white maxWidth"
                value="تایید و ورود"
            />
          </div>
          <p
              id="loginErrorBox"
              class="d-flex justify-content-center fontsize13 text-danger"
          >
            {{ loginError }}
          </p>
        </div>
        <div id="otherUserOption" class="d-flex px-2">
          <p
              class="d-inline align-items-center m-0 font-w-700"
              @click="forgetPassword()"
          >
            فراموشی رمز عبور
          </p>

          <p
              class="d-inline align-items-center m-0 font-w-700"
              @click="loginWithToken()"
          >
            ورود با رمز یکبار مصرف
          </p>
        </div>
      </form>
    </transition>
    <transition name="fade">
      <div v-if="signUpSection" id="signUpSection">
        <transition name="fade">
          <div
              v-if="signUpSectionPart1"
              id="registerSection"
              class="d-flex justify-content-center pb-3 px-2"
          >
            <div
                id="InsertMobile"
                class="d-flex flex-column align-items-center gap-15"
            >
              <div class="modal-custom-line"></div>
              <div
                  class="d-flex justify-content-center align-items-baseline gap-10"
              >
                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.20431 0.00436162C4.32696 -0.0132274 4.45022 0.0230506 4.54828 0.101096C4.64904 0.180241 4.71207 0.294564 4.72577 0.422626C4.75475 0.681494 4.56797 0.915635 4.30947 0.944768C2.52654 1.14373 1.14076 2.53262 0.940281 4.3211C0.913441 4.56071 0.712416 4.74155 0.472506 4.74155C0.454411 4.74155 0.436866 4.74045 0.418825 4.73825C0.29337 4.7245 0.181666 4.66241 0.102741 4.56346C0.0244207 4.46457 -0.0111647 4.34143 0.00308037 4.21557C0.252836 1.9841 1.97989 0.252241 4.20431 0.00436162ZM4.17004 1.91947C4.4319 1.8711 4.67236 2.03818 4.72219 2.29376C4.77202 2.54933 4.60443 2.79886 4.34973 2.84888C3.58286 2.99837 2.99074 3.59197 2.8407 4.36144C2.79796 4.58293 2.60293 4.74452 2.37837 4.74452C2.34823 4.74452 2.31814 4.74177 2.28745 4.73627C2.03275 4.68517 1.86516 4.43619 1.91499 4.18007C2.13901 3.0308 3.02362 2.14316 4.17004 1.91947ZM3.29995 7.19977C3.05168 7.05661 2.77118 6.89491 2.41742 6.97015C2.09699 7.03774 1.0048 7.92429 0.705706 8.23157C0.50963 8.43271 0.40062 8.64044 0.383625 8.84823C0.354091 9.66333 1.46163 10.5938 1.6632 10.7098C1.93105 10.9027 2.24268 11 2.5927 11C2.95037 11 3.34917 10.8983 3.78406 10.6955C6.14372 9.71058 9.74846 6.17705 10.7015 3.83567C11.097 2.96397 11.0997 2.24506 10.7081 1.70479C10.552 1.45141 9.66192 0.392288 8.86494 0.42581C8.65297 0.443949 8.44702 0.552778 8.2449 0.75064C7.93926 1.05018 7.07491 2.14558 7.00808 2.46711C6.93414 2.82437 7.09627 3.10852 7.24033 3.35805C7.27105 3.41169 7.31245 3.47534 7.35781 3.54508C7.52994 3.80976 7.75905 4.16204 7.67798 4.38694C7.09682 5.81378 5.75432 7.05864 4.32685 7.64346C4.10614 7.7237 3.75403 7.49293 3.49019 7.32006C3.4215 7.27501 3.35874 7.23387 3.30589 7.20318L3.29995 7.19977Z"
                      fill="#4F4F4F"
                  />
                </svg>
                <p class="fontsize15 text-center text-color-444 m-0">
                  لطفا شماره تماس خود را وارد کنید :
                </p>
                <!-- <p v-else class="fontsize12 mr-2 text-center">کد 5 رقمی ارسال شده به شماره تماس وارد شده را وارد کنید!</p>-->
              </div>
              <form
                  @submit.prevent="sendCodeToCustomer"
                  action=""
                  class="d-flex flex-column fontsize-medium mx-auto"
                  style="width: 90%"
              >
                <input
                    id="mobileInput"
                    v-model="telephone"
                    type="tel"
                    maxlength="11"
                    class="p-2 mb-2 text-center border-color"
                />
                <input
                    :class="{ disableLink: disabled }"
                    type="submit"
                    class="p-2 weight-bold bg-color-theme text-white fontsize-medium"
                    value="ورود و ارسال کد"
                />
                <p class="text-danger numberWar fontsize13" v-if="showAlert">
                  لطفا شماره همراه را به درستی وارد نمایید
                </p>
              </form>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div
              v-if="signUpSectionPart2"
              id="TokenSection"
              class="modal-body px-sm-1 px-3 pt-sm-1 pb-sm-1 pb-4 pb-3"
          >
            <div class="d-flex justify-content-center my-3 px-2">
              <svg
                  class="flex-shrink-0"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.00012 11.9993C2.00012 6.4803 6.48012 1.9993 12.0001 1.9993C17.5301 1.9993 22.0001 6.4803 22.0001 11.9993C22.0001 17.5203 17.5301 21.9993 12.0001 21.9993C6.48012 21.9993 2.00012 17.5203 2.00012 11.9993ZM11.1201 8.2093C11.1201 7.7303 11.5201 7.3293 12.0001 7.3293C12.4801 7.3293 12.8701 7.7303 12.8701 8.2093V12.6293C12.8701 13.1103 12.4801 13.4993 12.0001 13.4993C11.5201 13.4993 11.1201 13.1103 11.1201 12.6293V8.2093ZM12.0101 16.6803C11.5201 16.6803 11.1301 16.2803 11.1301 15.8003C11.1301 15.3203 11.5201 14.9303 12.0001 14.9303C12.4901 14.9303 12.8801 15.3203 12.8801 15.8003C12.8801 16.2803 12.4901 16.6803 12.0101 16.6803Z"
                    fill="#a5a5a5"
                />
              </svg>
              <p class="fontsize-mini mr-sm-1 mr-1 text-center text-color-grey">
                کد 5 رقمی ارسال شده به شماره همراه خود را وارد کنید!
              </p>
            </div>
            <div class="text-center">
              <span class="fontsize15 text-color-444">{{ telephone }}</span>
              <button
                  type="button"
                  @click.prevent="editTelephone()"
                  class="mr-4 bg-none"
              >
                <span class="fontsize14 edit-number">ویرایش شماره</span>
              </button>
            </div>
            <div
                :class="{ codeInputFullForm: showFullForm }"
                id="codeInput"
                class="input-login-code text-center mt-4"
            >
              <div
                  class="d-flex align-items-center justify-content-between px-3 flex-row-reverse"
              >
                <input
                    v-model="codeInput[0]"
                    class="fontsize-medium text-center"
                    id="codeInput1"
                    ref="codeInput1"
                    @keyup="changeInput"
                    @click="selectInput"
                    :type="mediaQueries.mobileSize ? 'tel' : 'number'"
                    maxlength="1"
                />
                <input
                    @keyup="changeInput"
                    v-model="codeInput[1]"
                    class="fontsize-medium text-center"
                    @click="selectInput"
                    id="codeInput2"
                    :type="mediaQueries.mobileSize ? 'tel' : 'number'"
                    maxlength="1"
                />
                <input
                    @keyup="changeInput"
                    class="fontsize-medium text-center"
                    v-model="codeInput[2]"
                    @click="selectInput"
                    id="codeInput3"
                    :type="mediaQueries.mobileSize ? 'tel' : 'number'"
                    :maxlength="1"
                />
                <input
                    @keyup="changeInput"
                    class="fontsize-medium text-center"
                    v-model="codeInput[3]"
                    @click="selectInput"
                    id="codeInput4"
                    :type="mediaQueries.mobileSize ? 'tel' : 'number'"
                    maxlength="1"
                />
                <input
                    @keyup="changeInput"
                    class="fontsize-medium text-center"
                    :type="mediaQueries.mobileSize ? 'tel' : 'number'"
                    v-model="codeInput[4]"
                    @click="selectInput"
                    id="codeInput5"
                    maxlength="1"
                />
              </div>
              <input
                  v-if="!showFullForm"
                  :class="{ disableLink: disabledVerify }"
                  @click.prevent="verify()"
                  type="submit"
                  class="p-2 mt-3 fontsize-medium weight-bold bg-color-theme text-white"
                  value="تایید کد"
              />
            </div>
            <input
                v-if="showFullForm"
                :class="{
                disableLink: disabledVerify,
                verifyFullFrom: showFullForm,
              }"
                @click.prevent="verify()"
                type="submit"
                class="p-3 mt-3 fontsize-medium weight-bold bg-color-theme text-white"
                value="تایید کد"
            />
            <div>
              <p style="margin: 2px" class="text-danger numberWar">
                {{ verfiyError }}
              </p>
            </div>
            <div id="otherUserOption" class="d-none px-2 m-2 mr-3">
              <!--d-flex-->
              <p
                  v-if="tokenName == 'login'"
                  class="d-inline align-items-center m-0 font-w-700"
                  @click="loginWithPassword()"
              >
                ورود با رمز عبور
                <svg
                    fill="#ffffff"
                    width="16"
                    height="16"
                    viewBox="-128 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#ffffff"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"/>

                  <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  />

                  <g id="SVGRepo_iconCarrier">
                    <path
                        d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                    />
                  </g>
                </svg>
              </p>
            </div>
          </div>
        </transition>
        <div v-if="false"> <!-- برای محکم کاری این شرط رو گذاشتم 😂 -->
          <transition name="fade" v-if="showFullForm">
            <form
                @submit.prevent="signUpCustomer"
                v-if="signUpSectionPart3"
                class="modal-body px-sm-4 px-3 pt-sm-2 pb-sm-2 pb-3"
                id="signUpForm"
            >
              <template v-if="!showFullForm">
                <p class="d-flex justify-content-center">
                  یک رمزعبور برای حساب کاربری خود وارد کنید
                </p>
                <div class="">
                  <label class="fontsize-mini text-color-grey mb-1 pr-2"
                  >رمز عبور <span class="text-danger">*</span></label
                  >
                  <div
                      class="d-flex align-items-center border border-r-18 px-2 py-2"
                  >
                    <i class="fa fa-key"></i>
                    <input
                        class="loginInputs"
                        :type="isSafari() ? 'text' : 'password'"
                        v-model="password"
                        id="password"
                        placeholder="رمز عبور (حداقل 6 کاراکتر)"
                        :style="mediaQueries.mobileSize ? 'direction: ltr' : ''"
                    />
                  </div>
                </div>
                <div v-if="!hasInviteLink" class="mt-2">
                  <div class=" pt-1 pr-1 pb-1 d-flex align-items-baseline ">
                    <input type="checkbox" class="d-none" @input="showReagentInput" v-model="showReagent" name=""
                           id="number-of-reagent">
                    <span @click="showReagentInputSpan" class="checked ml-1"></span>
                    <label for="number-of-reagent" class="fontsize-mini text-color-grey mb-1 pr-1 labelReagent">
                      اگر شماره موبایل معرف دارید (اختیاری) !</label>
                  </div>
                  <div id="reagent" class="overflow-hidden">
                    <input
                        placeholder="شماره موبایل معرف را وارد کنید"
                        class="w-100 pr-3 border"
                        type="tel"
                        v-model="reprezentantMobile"
                    />
                  </div>
                </div>
              </template>
              <template v-else>
                <div id="registerForm">
                  <div class="col-md-6">
                    <div class="p-3 d-flex fontsize14 flex-column weight-bold">
                      <label class="text-color-999" for="">نام:</label>
                      <input
                          class="text-color-444 bg-color-eai p-md-3 p-2"
                          type="text"
                          ref="firstName"
                          :class="{ inputError: firstNameError }"
                          v-model="first_name"
                      />
                    </div>
                    <div class="p-3 d-flex fontsize14 flex-column weight-bold">
                      <label class="text-color-999" for="">نام خانوادگی:</label>
                      <input
                          class="text-color-444 bg-color-eai p-md-3 p-2"
                          type="text"
                          ref="lastName"
                          :class="{ inputError: lastNameError }"
                          v-model="last_name"
                      />
                    </div>
                    <div class="p-3 d-flex fontsize14 flex-column weight-bold">
                      <label class="text-color-999" for="">ایمیل:</label>
                      <input
                          class="text-color-444 bg-color-eai p-md-3 p-2"
                          type="email"
                          ref="email"
                          :class="{ inputError: emailError }"
                          v-model="email"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="p-3 d-flex fontsize14 flex-column weight-bold">
                      <label class="text-color-999" for="">رمز عبور:</label>
                      <input
                          class="text-color-444 bg-color-eai p-md-3 p-2"
                          :type="isSafari() ? 'text' : 'password'"
                          :class="{ inputError: passwordError }"
                          ref="password"
                          v-model="password"
                      />
                    </div>
                    <div class="p-3 d-flex fontsize14 flex-column weight-bold">
                      <label class="text-color-999" for="">تاریخ تولد:</label>
                      <date-picker
                          format="x"
                          ref="brithDay"
                          v-model="brithDay"
                          display-format="YYYY/MM/DD"
                          color="#121314"
                      />
                    </div>

                    <div
                        id="chooseGenderAndImageBox"
                        class="m-3 pb-3 d-flex fontsize14 flex-row weight-bold"
                    >
                      <span class="text-color-999 ml-4 mt-1">جنسیت:</span>
                    </div>
                    <div class="d-flex">
                      <treeselect
                          :clearable="false"
                          :options="genderOption"
                          v-model="gender"
                      />
                    </div>
                  </div>
                </div>
              </template>
              <!-- <hr class="mt-0"/> -->
              <input
                  :class="{
                disableLink: signUpDisabled,
                signUpFullForm: showFullForm,
              }"
                  type="submit"
                  class="p-3 mt-3 fontsize-small weight-bold bg-color-theme text-white maxWidth"
                  value="ثبت اطلاعات"
              />
              <p class="mt-2 d-flex justify-content-center text-danger">
                {{ SignUpError }}
              </p>
            </form>
          </transition>
        </div>

      </div>
    </transition>
    <transition name="fade">
      <div
          v-if="forgetSection"
          id="forgetSection"
          :class="{ 'forget-Full-Form': showFullForm }"
          class="modal-body px-sm-5 px-3 pt-sm-4 pb-sm-5 pb-3"
      >
        <p class="d-flex justify-content-center">
          لطفا در ورود اطلاعات دقت فرمایید
        </p>
        <div>
          <i class="fa fa-key"></i>
          <input
              :style="mediaQueries.mobileSize ? 'direction: ltr' : ''"
              class="loginInputs"
              :type="isSafari() ? 'text' : 'password'"
              v-model="newPassword"
              id="newPassword"
              placeholder="رمز عبور"
          />
        </div>
        <hr class="mt-0"/>
        <div>
          <i class="fa fa-key"></i>
          <input
              :style="mediaQueries.mobileSize ? 'direction: ltr' : ''"
              class="loginInputs"
              :type="isSafari() ? 'text' : 'password'"
              v-model="newRePassword"
              id="newRePassword"
              placeholder="تکرار رمز عبور"
              @keypress.enter="sendNewPassword()"
          />
        </div>
        <hr class="mt-0"/>
        <input
            :class="{ disableLink: forgetDisabled }"
            @click.prevent="sendNewPassword()"
            type="submit"
            class="p-3 mt-3 fontsize-small weight-bold bg-color-theme text-white maxWidth"
            value="ثبت اطلاعات"
        />
        <p class="d-flex justify-content-center text-danger">
          {{ forgetError }}
        </p>
      </div>
    </transition>
  </div>
</template>
<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {Treeselect} from "@riophae/vue-treeselect";
import cart from "@/parts/Front/components/cart/cart";
import $ from "jquery";
import {isSafari} from "@/libraries/functions";


//  قبل از اینکه دست بزنی یه چیزی نیاز داره که بدونی که مرجله 3 کلا وجود نداره و همزمان با درخواست وریفای در استپ دوم کاربر لاگین میشه چه تایپ register باشه چه login
// هچنین یه مقدار showFullForm داره به صورت پراپس دربافت میشه که به صورت استاتیک تعریف شده وfalse هستش که مشخص میکنه که فرم سوم نشون داده بشه یا نه


export default {
  components: {
    Treeselect,
  },
  props: {
    showFullForm: {Boolean},
    value: {String},
    textModalHeader: {String},
  },
  inject: ["mediaQueries"],
  name: "LoginRegister",

  data() {
    return {
      genderError: false,
      passwordError: false,
      emailError: false,
      lastNameError: false,
      firstNameError: false,
      gender: "male",
      genderOption: [
        {
          id: "male",
          label: "مرد",
        },
        {
          id: "female",
          label: "زن",
        },
      ],
      brithDay: null,
      first_name: null,
      last_name: null,
      email: null,
      mobileModal: false,
      showPass: false,
      disabledVerify: false,
      loginError: "",
      loginPassword: null,
      mobile: null,
      newsletter: false,
      foreign_national: false,
      national_code: null,
      hasInviteLink: false,
      password: null,
      codeInput: [],
      textModal: this.textModalHeader,
      loginSection: false,
      TokenSection: false,
      signUpSection: true,
      signUpSectionPart1: true,
      signUpSectionPart2: false,
      signUpSectionPart3: false,
      forgetSection: false,
      telephone: null,
      verfiyError: "",
      disabled: false,
      disabled1: false,
      showAlert: false,
      tokenName: "",
      newRePassword: null,
      newPassword: null,
      forgetDisabled: false,
      forgetError: "",
      name: null,
      lastName: null,
      city: null,
      address: null,
      postalCode: null,
      signUpDisabled: false,
      SignUpError: "",
      reprezentantMobile: null,
      showReagent: false,
    };
  },
  methods: {
    showReagentInput() {
      const Reagent = document.querySelector("#reagent");
      if (this.showReagent) {
        this.showReagent = false;
        Reagent.style.maxHeight = null;
      } else {
        Reagent.style.maxHeight = Reagent.scrollHeight + "px";
        this.showReagent = true;
      }
    },
    showReagentInputSpan() {
      const Reagent = document.querySelector("#reagent");
      if (this.showReagent) {
        this.showReagent = false;
        Reagent.style.maxHeight = null;
      } else {
        Reagent.style.maxHeight = Reagent.scrollHeight + "px";
        this.showReagent = true;
      }
    },
    isSafari,
    focusOnInput(e) {
      alert("ok");
      e.stopPropagation();
      e.target.select();
    },
    // برای بازگشت به فرم ورود شماره تماس
    editLoginTelephone() {
      this.loginSection = false;
      setTimeout(() => {
        this.signUpSection = true;
      }, 500);
    },
    // دکمه بازگشت در فرم فراموشی رمز عبور
    backToLogin() {
      this.signUpSection = false;
      setTimeout(() => {
        this.loginSection = true;
        setTimeout(() => {
          this.$refs.passWord.select();
        });
        this.textModal = "ورود به وبسایت";
      }, 500);
    },
    // این تابع برای کلیک بروی ورودی ها است
    selectInput(e) {
      e.target.select();
    },

    // حرکت در بین اینپوت ها
    changeInput(event) {
      if (event.target.value.length === 5) {
        for (let i = 0; i <= 4; i++) {
          this.codeInput[i] = event.target.value[i];
        }
        this.verify();
        document.getElementById("loginModal").focus();
        return;
      }
      let stringId = event.target.id;
      let numberId = parseInt(stringId[stringId.length - 1]);
      if (event.key == "Backspace") {
        document.getElementById("codeInput" + (numberId - 1)).select();
      } else {
        if (numberId == 5) {
          this.verify();
          document.getElementById("loginModal").focus();
        } else {
          document.getElementById("codeInput" + (numberId + 1)).select();
        }
      }
    },
    // تابع تغییر رمز عبور کاربر
    sendNewPassword() {
      // اعتبار سنجی ورودی ها
      if (this.newPassword == null || this.newPassword.length < 4) {
        return (this.forgetError = "رمز عبور می بایست از 4 کارکتر بیشتر باشد");
      } else if (this.newRePassword != this.newPassword) {
        return (this.forgetError = "تکرار رمز عبور اشتباه است");
      }
      this.forgetDisabled = true;
      this.forgetError = "";
      // ساخت فرم دیتا برای ارسال
      const formdata = new FormData();
      formdata.append("mobile", this.telephone);
      formdata.append("password", this.newPassword);
      formdata.append("password_confirmation", this.newRePassword);
      formdata.append("_method", "put");
      formdata.append("sms_token", this.$root.getCookie("Token"));
      this.$axios
          .post("/customer/password/reset", formdata, {
            params: cart.getCartRequestQueryString(),
          })
          .then((response) => {
            // بعد از دریافت پاسخ ست کردن کوکی
            this.$store.commit(
                "front/setToken",
                response.data.data.data.access_token
            );

            this.$store.commit("front/setLoginStatus", response.data.data.data);
            // غیرفعال کردن دکمه
            this.forgetDisabled = false;
            // خروج از مودال
            document.getElementById("loginModal").click();
            window.swal({
              title: "خوش آمدید",
              text: "رمز عبور شما با موفقیت تغییر کرد",
              icon: "success",
              timer: 1500,
            });
          })
          .catch((error) => {
            // در صورت دریافت خطا نمایش به کاربر
            if (error.statusCode === 422) {
              this.forgetError = error;
            }
          });
    },
    closeModal() {
      // برگرداندن به وضعیت اولیه با بستن مودال
      setTimeout(() => {
        this.loginSection = false;
        this.TokenSection = false;
        this.signUpSection = true;
        this.signUpSectionPart1 = true;
        this.signUpSectionPart2 = false;
        this.signUpSectionPart3 = false;
        this.verfiyError = "";
        this.forgetSection = false;
        this.disabledVerify = false;
        this.disabled1 = false;
        this.disabled = false;
        this.forgetDisabled = false;
        this.signUpDisabled = false;
        this.textModal = "ورود به وبسایت";
        this.loginError = "";
        this.forgetError = "";
        this.SignUpError = "";
        this.tokenName = "";
        this.telephone = "";
        this.password = null;
        this.loginPassword = null;
        this.showAlert = false;
        this.forgetError = "";
        // دادن زمان برای انجام فرآیند های زیر تا در نمایش به کاربر زیبا تر شود
      }, 1000);
    },

    signUp() {
      // شروع مراحل عضو کردن
      this.loginSection = false;

      setTimeout(() => {
        // نمایش بخش عضویت

        this.signUpSection = true;
        this.textModal = "عضویت";
      }, 500);
      // مقدار  توکن نی م که مشخص میکند از فرم ورود شماره برای چه استفاده شده است
      this.tokenName = "register";
    },
    // بعد از فشردن دکمه فراموشی رمز عبور تابع زیر صدا زده میشود تا بخش فراموشی رمز عبور نمایش داده شود
    forgetPassword() {
      this.loginSection = false;
      setTimeout(() => {
        this.textModal = "فراموشی رمز عبور";
        this.signUpSection = true;
      }, 500);
      // مقدار  توکن نی م که مشخص میکند از فرم ورود شماره برای چه استفاده شده است
      this.tokenName = "forget";
    },
    // این تابع توکن را از سمت سرور برای مشتری میفرستد تا در بخش های مختلف از آن استفاده شود
    sendCodeToCustomer() {
      this.mobile = this.telephone;
      // تابع اولیه برای اهراز موبایل و ارسال کد پنج رقمی
      if (this.telephone == null || this.telephone.length != 11) {
        this.showAlert = true;
      } else {
        this.showAlert = false;
        this.disabled = true;
        const formData = new FormData();
        formData.append("mobile", this.telephone);
        formData.append(
            this.content ? this.content.settings.general.smsBomberKey : "",
            this.content ? this.content.settings.general.smsBomberValue : ""
        );
        // چه شماره همراه درست بود چه غلط پنجره ورودکد نمایش داده شود تا اگر شماره اشتباهی وارد کرده تصحیح کنه
        if (this.tokenName == "forget" || this.tokenName == "login") {
          this.$axios.post("/customer/send/token", formData).then((res) => {
            this.disabled = false;
            // برای نمایش قسمت گرفتن توکن
            // استفاده از تایم اوت برای نمایش درست کلاس های فید
            this.signUpSectionPart1 = false;
            // تعمیر استایل
            setTimeout(() => {
              this.signUpSectionPart2 = true;
              setTimeout(() => {
                this.$refs.codeInput1.focus();
              });
            }, 500);
          });
        } else {
          this.$axios
              .post("/customer/register-login", formData)
              .then((res) => {
                this.disabled = false;
                this.showAlert = false;
                if (res.data.data.status == "login") {
                  this.tokenName = "login";

                  this.signUpSection = true;
                  this.signUpSectionPart1 = false;
                  setTimeout(() => {
                    this.signUpSectionPart2 = true;
                    setTimeout(() => {
                      this.$refs.codeInput1.focus();
                    });
                  }, 500);
                  this.sendCodeToCustomer();
                  // setTimeout(() => {
                  //   this.loginSection = true;
                  //   setTimeout(() => {
                  //     this.$refs.passWord.select();
                  //   });
                  // }, 500);
                } else {
                  this.tokenName = "register";

                  this.signUpSectionPart1 = false;
                  setTimeout(() => {
                    this.signUpSectionPart2 = true;

                    setTimeout(() => {
                      this.$refs.codeInput1.focus();
                    });
                  }, 500);
                }
                // برای نمایش قسمت گرفتن توکن
                // استفاده از تایم اوت برای نمایش درست کلاس های فید
              })
              .catch((error) => {
                this.showAlert = true;

                this.disabled = false;
              });
        }
      }
    },
    // گر شماره تلفن را وارد کرد و وارد بخش ارسال توکن به سمت سرور برای احراز هویت شد و شماره را اشتباه وارد کرده بود با فشردن دکمه ویرایش شماره این تابع صدا زده میشود

    editTelephone() {
      // خالی کردن ورودی
      while (this.codeInput.length > 0) {
        this.codeInput.pop();
      }
      // بازگشت به فرم برای ویرایش شماره همراه
      this.signUpSectionPart2 = false;
      setTimeout(() => {
        this.signUpSectionPart1 = true;

        // فعال کردن دکمه
        this.disabled = false;
      }, 500);
    },
    // بعد از نمایش فرم تکمیل اطلاعات برای ارسال اطلاعات به سمت سرور این تابع صدا زده میشود
    signUpCustomer() {
      // اعتبار سنجی اولیه
      // اگر فرم به صورت کامل بود
      if (this.showFullForm) {
        if (this.first_name == null || this.first_name.length < 1) {
          this.firstNameError = true;
          return window
              .swal({
                title: "",
                icon: "warning",
                showConfirmButton: true,
                text: "کاربر عزیز وارد کردن نامی با بیش از دو کارکتر الزامی است",
                button: "متوجه شدم",
              })
              .then(() => {
                this.$refs.firstName.scrollIntoView({
                  behavior: "auto",
                  block: "center",
                  inline: "center",
                });
                this.$refs.firstName.focus();
              });
        } else if (this.last_name == null || this.last_name.length < 3) {
          this.firstNameError = false;
          this.lastNameError = true;
          return window
              .swal({
                title: "",
                icon: "warning",
                showConfirmButton: true,
                text: "کاربر عزیز وارد کردن نامی با بیش از سه کارکتر الزامی است",
                button: "متوجه شدم",
              })
              .then(() => {
                this.$refs.lastName.scrollIntoView({
                  behavior: "auto",
                  block: "center",
                  inline: "center",
                });
                this.$refs.lastName.focus();
              });
        }
        this.firstNameError = false;
        this.lastNameError = false;
        if (this.email != null && this.email.length > 5) {
          if (!this.validEmail(email)) {
            this.emailError = true;
            return window
                .swal({
                  title: "",
                  icon: "warning",
                  showConfirmButton: true,
                  text: "کاربر عزیز وارد کردن نامی با بیش از سه کارکتر الزامی است",
                  button: "متوجه شدم",
                })
                .then(() => {
                  this.$refs.email.scrollIntoView({
                    behavior: "auto",
                    block: "center",
                    inline: "center",
                  });
                  this.$refs.email.focus();
                });
          } else {
            this.emailError = false;
          }
        }
        if (this.password == null || this.password.length < 5) {
          this.passwordError = true;
          return window
              .swal({
                title: "",
                icon: "warning",
                showConfirmButton: true,
                text: "کاربر عزیز وارد کردن رمز عبوری با بیش از 5 کارکتر الزامی است",
                button: "متوجه شدم",
              })
              .then(() => {
                this.$refs.lastName.scrollIntoView({
                  behavior: "auto",
                  block: "center",
                  inline: "center",
                });
                this.$refs.lastName.focus();
              });
        }
      } else {
        // اگر فرم فقط پسورد را میخواست
        if (this.password == null) {
          this.signUpDisabled = false;
          return (this.SignUpError = "لطفا یک رمز عبور انتخاب کنید");
        } else if (this.password.length < 4) {
          this.signUpDisabled = false;
          return (this.SignUpError = "رمزعبور باید حداقل 6 رقم باشد");
        }
        if (this.reprezentantMobile && this.reprezentantMobile.length != 11) {
          this.signUpDisabled = false;
          return (this.SignUpError = "شماره موبایل باید 11 رقم باشد");
        }
      }
      // ساخت فرم دیتا از اطاعات وارد شده
      const formdata = new FormData();

      formdata.append("mobile", this.telephone);
      formdata.append("password", this.password);
      if (this.reprezentantMobile) {
        formdata.append("reprezentant_mobile", this.reprezentantMobile);
      }
      if (this.hasInviteLink) {
        formdata.append(
            "reprezentant_code",
            localStorage.getItem("inviteLink")
        );
        localStorage.removeItem("inviteLink");
      }
      if (this.showFullForm) {
        formdata.append("first_name", this.first_name);
        formdata.append("last_name", this.last_name);
        formdata.append("gender", this.gender);
        if (this.brithDay != null) {
          formdata.append("gender", this.brithDay);
        }
        if (this.email.length > 6) {
          formdata.append("email", this.email);
        }
      }
      formdata.append("sms_token", this.$root.getCookie("dailyAuthorization"));
      // ارسال اطلاعات به سمت سرور
      this.signUpDisabled = true;
      this.$axios
          .post("/customer/register", formdata, {
            params: cart.getCartRequestQueryString(),
          })
          .then((response) => {
            this.signUpDisabled = false;

            // بعد از دریافت پاسخ درست ست کردن کوکی و بعد خروج از مدال
            this.$store.commit(
                "front/setToken",
                response.data.data.data.access_token
            );
            this.$store.commit("front/setLoginStatus", response.data.data.data);

            document.getElementById("loginModal").click();
          })
          .catch((error) => {
            this.signUpDisabled = false;
            this.$root.notify(error);
          });
    },
    // اعتبار سنجی ایمیل
    validEmail: function (email) {
      var re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    // این تابع برای اهراز هویت توکن ارسالی از سمت مشتری است
    verify() {
      this.mobile = this.telephone;
      const formdata = new FormData();
      formdata.append("mobile", this.telephone);
      // چک برای اینکه کد فعالسازی برای ورود است یا عضویت یا فراموشی رمز عبور
      if (this.tokenName == "login") {
        formdata.append("type", "login");
      } else if (this.tokenName == "register") {
        formdata.append("type", "register");
      } else {
        formdata.append("type", "forget");
      }
      this.disabledVerify = true;
      // استفاده از تابع جوین برای متصل کردن اینپوت ها به هم
      let token = this.codeInput.join("");
      formdata.append("sms_token", token);
      this.$axios
          .post("/customer/verify", formdata, {
            params: cart.getCartRequestQueryString(),
          })
          .then((response) => {
            this.codeInput = [];

            this.disabledVerify = false;
            this.verfiyError = "";
            // اگر برای لاگین بود توکن ست شود
            if (this.tokenName == "login") {
              this.$store.commit(
                  "front/setToken",
                  response.data.data.data.access_token
              );

              this.$store.commit("front/setLoginStatus", response.data.data.data);
              this.$store.commit("front/setUserCart", {
                priority: 0,
                cart: new cart(this, response.data.data.data.carts, "server"),
              });
              document.getElementById("loginModal").click();
              // اگر برای فراموشی بود فرم رمز جدید نمایش داده شود
            } else if (this.tokenName == "forget") {
              // اگر برای عضویت بود برویم سراغ پارت دوم و ست کردن توکن روزانه
              this.signUpSection = false;
              setTimeout(() => {
                this.forgetSection = true;
              }, 500);
              this.$root.setCookie("Token", token, 365);
            } else if (this.tokenName == "register") {
              formdata.append("mobile", this.telephone);
              formdata.append("password", this.password);
              if (localStorage.getItem("inviteLink")) {
                formdata.append(
                    "reprezentant_code",
                    localStorage.getItem("inviteLink")
                );
                localStorage.removeItem("inviteLink");
              }
              this.$axios
                  .post("/customer/register", formdata, {
                    params: cart.getCartRequestQueryString(),
                  })
                  .then((response) => {
                    this.signUpDisabled = false;

                    // بعد از دریافت پاسخ درست ست کردن کوکی و بعد خروج از مدال
                    this.$store.commit(
                        "front/setToken",
                        response.data.data.data.access_token
                    );
                    this.$store.commit("front/setLoginStatus", response.data.data.data);

                    document.getElementById("loginModal").click();
                  })
                  .catch((error) => {
                    this.signUpDisabled = false;
                    this.$root.notify(error);
                  });
            }
            // {
            // this.signUpSectionPart2 = false;
            // setTimeout(() => {
            //   this.signUpSectionPart3 = true;
            //   this.$nextTick(() => {
            //     document.getElementById("password").select();
            //   });
            // inja
            // }, 500);
            // this.$root.setCookie("dailyAuthorization", token, 365);
            // }
          })
          .catch((error) => {
            this.disabledVerify = false;
            this.verfiyError = "کد وارد شده صحیح نمی باشد";
          });

    },
    // با فشردن دکمه ورود با رمز یکبار مصرف داریم
    loginWithToken() {
      // بخش لاگین غیرفعال و بخش اولیه عضویت برای استفاده از فرمش فعال میشود
      this.loginSection = false;
      setTimeout(() => {
        this.signUpSectionPart2 = true;

        setTimeout(() => {
          this.$refs.codeInput1.focus();
        });

        this.signUpSectionPart1 = false;
        this.signUpSection = true;
      }, 500);
      // مقدار  توکن نی م که مشخص میکند از فرم ورود شماره برای چه استفاده شده است
      this.tokenName = "login";
      this.sendCodeToCustomer();
    },
    loginWithPassword() {
      this.signUpSection = false;
      this.signUpSectionPart1 = false;
      this.signUpSectionPart2 = false;
      setTimeout(() => {
        this.loginSection = true;
        setTimeout(() => {
          this.$refs.passWord.select();
        });
      }, 500);
    },
    // ورود مستقیم با رمز عبور با این تابع صورت میگیرد
    login() {
      if (this.loginPassword == null || this.loginPassword.length < 4) {
        return (this.loginError = "رمز عبور شما از چهار کارکتر بیشتر بوده است");
      }
      this.disabled1 = true;
      this.loginError = "";
      const formdata = new FormData();
      formdata.append("mobile", this.mobile);
      formdata.append("password", this.loginPassword);
      if (this.$root.pushToken) {
        formdata.append("device_token", this.$root.pushToken);
      }
      // ساخت فرم دیتا و ارسال به سمت سرور
      this.$axios
          .post("/customer/login", formdata, {
            params: cart.getCartRequestQueryString(),
          })
          .then((response) => {
            const data = response.data.data.data;
            // بعد از دریافت پاسخ ست کردن کوکی و خروج از مودال
            this.$store.commit(
                "front/setToken",
                response.data.data.data.access_token
            );
            this.$store.commit("front/setUserInfo", response.data.data.data.user);
            this.$store.commit("front/setLoginStatus", data);
            this.disabled1 = false;

            document.getElementById("loginModal").click();
          })
          .catch((error) => {
            console.error(error);
            this.disabled1 = false;
            this.loginError = "شماره موبایل یا رمز عبور شما اشتباه بوده است";
          });
    },
  },
  watch: {
    textModal(val) {
      this.$emit("changeTextModal", val);
    },
    value(val) {
      this.$emit("input", val);
    },
    showPass(newVal) {
      if (newVal) {
        document.getElementById("passWord").type = "text";
      } else {
        document.getElementById("passWord").type = "password";
      }
    },
    // showReagent(newVal) {
    //   const Reagent = document.querySelector("#reagent");
    //   if (newVal) {
    //     this.showReagent = false
    //     Reagent.style.maxHeight = null
    //   }else{
    //     Reagent.style.maxHeight = Reagent.scrollHeight + "px";
    //     this.showReagent = true
    //   }
    // }
  },
  computed: {
    //  استفاده از این متد برای نمایش بهتر مدال و فرم ها
    modalHeight() {
      if (this.loginSection) {
        this.value = "350px !important";
      } else if (this.forgetSection) {
        this.value = "430px !important";
      } else {
        if (this.signUpSectionPart1) {
          this.value = "330px !important";
        }
        if (this.signUpSectionPart2) {
          this.value = "380px !important";
        }
        if (this.signUpSectionPart3) {
          this.value = "300px !important";
        }
        this.value = "300px !important";
      }
    },
    content() {
      return this.$store.getters["front/getHomeData"];
    },
  },
  mounted() {
    this.hasInviteLink = localStorage.getItem("inviteLink") ? true : false;
    let RespLoginModal = this;
    $("#loginModal").on("hidden.bs.modal", function () {
      RespLoginModal.closeModal();
    });
  },
};
</script>

<style scoped lang="scss">
/* افزودن استایل به کدهای اضافه شده به قالب */
#mobile {
  width: 95%;
  background: white;
  height: 30px;
  border-radius: 0;
}

#signupCheckbox {
  display: flex;
  justify-content: space-around;
}

#signupCheckbox div input {
  position: relative;
  top: 4px;
  left: 5px;
}

#codeInput {
  direction: ltr;
}

#otherUserOption {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: auto;
}

#otherUserOption p {
  cursor: pointer;
  padding: 2px 11px;
  border-radius: 25px;
  color: #ffffff;
  font-size: small;
  background-color: rgb(21, 190, 149);
}

#otherUserOption p:hover {
  /* text-shadow: -1px 1px 7px blue1; */
}

.loginInputs {
  width: 95%;
  padding-right: 5px;
  line-height: 36px;
}

#closeModal2 {
  background-color: silver;
}

#continueSignUpSection {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

#continueSignUpSection input {
  /*border-radius: 5px;*/
  margin-left: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

#loginHeader {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}

#loginWithTokenLink {
  cursor: pointer;
  transition: text-shadow 1s;
}

#loginWithTokenLink:hover {
  text-shadow: -1px 1px 7px blue;
}

/* اصلاح قالب
 */
/* درست کردن قالب ورود کد */
.login-modal .modal-body input[type="tel"],
.login-modal .modal-body input[type="number"] {
  background-color: #dddddd;
  /*border-radius: 10px;*/
  width: 50px;
  height: 50px;
  border-radius: 15px;
  border: 1px solid #c7c7c7;
}

@media screen and (max-width: 390px) {
  .login-modal .modal-body input[type="tel"],
  .login-modal .modal-body input[type="number"] {
    width: 40px;
    height: 40px;
    border-radius: 15px;
    border: 1px solid #c7c7c7;
  }
}

/* برداشتن دکمه های بالا پایین برای ورود عدد */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#showSignUpResult {
  text-align: center;
  display: flex;
  flex-direction: column;
}

#showSignUpResult i {
  color: green;
  font-size: 50px;
}

.login-modal .modal-content {
  height: max-content !important;
}

#mobileInput,
#passWord {
  font-size: 16px;
  /*border-radius: 10px;*/
}

#mobileInput {
  /* background-color: #c0c0c026; */
  border-radius: 25px;
  width: 100%;
  border: 1px solid #c7c7c7;
}

#modal-content {
  transition: all 0.3s;
  /* height: 300px; */
  background: #f7e9e3;
}

.modal-content {
  /* min-height: 340px; */
}

#reagent {
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

/* نمایش تمام صفحه مودال در حالت موبایلی
 */
@media (max-width: 768px) {
  #modal-content {
    height: 100vh !important;
    border-radius: 0 !important;
    width: 100% !important;
    position: fixed;
    top: 0;
  }

  .modal-dialog {
    margin: 0;
  }
}

#hrInput {
  margin-top: 0;
}

#modal-content {
  transition: all 1s;
}

#loginErrorBox {
  margin-top: 5px;
}

#backBtn {
  background: transparent;
  font-size: larger;
}

.numberWar {
  margin: 2px;
  padding: 10px;
  text-align: center;
}

#btnCloseLoginModal {
  background-color: transparent;
}

.inputError {
  background-color: #f5eae7;
  border: 1px solid pink;
}

#chooseGenderAndImageBox {
  margin-bottom: 0 !important;
}

/* full from */
#registerForm {
  display: flex;
  flex-wrap: wrap;
}

.marginAuto {
  margin: auto;
}

.codeInputFullForm input[type="number"] {
  width: 40px;
  font-size: 22px;
  /*border-radius: 5px;*/
  background-color: #e5e5e5;
}

.verifyFullFrom {
  width: 30%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paswordInputFullForm {
  width: 80%;
  margin: auto;
}

.forget-Full-Form {
  width: 60%;
  margin: auto;
}

.signUpFullForm {
  width: 60%;
  margin: auto;
  display: flex;
  justify-content: center;
  font-size: larger;
}

#InsertMobile form input[type="submit"] {
  border-radius: 25px;
}

.edit-number {
  padding: 0 10px;
  border-radius: 25px;
  color: #ffffff;
  font-size: x-small;
  background-color: rgb(21, 190, 149);
}

/* media */
@media (max-width: 768px) {
  .codeInputFullForm input[type="tel"] {
    width: 40px;
    font-size: 22px;
    /*border-radius: 5px;*/
    background-color: #e5e5e5;
  }

  .forget-Full-Form {
    width: 90%;
    margin: auto;
  }

  #otherUserOption p {
    cursor: pointer;
    padding: 2px 11px;
    border-radius: 25px;
    color: #ffffff;
    font-size: small;
    background-color: rgb(21, 190, 149);
  }
}

/*create checkbox*/

label[for="number-of-reagent"] {
  cursor: pointer;
}

.checked {
  position: relative;
  cursor: pointer;
  bottom: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  background: var(--color-f0);
  border-radius: 3px;
  border: 1px solid #c7c7c7;
  transition: 0.2s ease;

  &:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 12px;
    bottom: 4px;
    border: solid white 2px;
    border-left: none;
    border-top: none;
    rotate: 40deg;
    display: none;
  }
}

#number-of-reagent:checked + .checked {
  background: #0075FF;

  &:after {
    display: block;
  }
}

/*create checkbox*/
</style>
